import React from 'react';
import NavbarJSX from './Navbar.jsx';
import './Navbar.css';

const Navbar = () => {
    const items = [
        { label: 'About', url: '/about' },
        { label: 'Contact', url: '#' },
        { label: 'Resources', url: '#' },
        { label: 'Blog', url: '#' },
        { label: 'YouTube', url: '#', className: 'youtube' },
    ];

    return <NavbarJSX items={items} />;
};

export default Navbar;