import React from 'react';
import HomeJSX from './Home.jsx';

function Home() {
  const handleGetStarted = () => {
    console.log('Get Started clicked');
    // Implement your "Get Started" logic here
    // For example, you could navigate to a new page:
    // history.push('/get-started');
  };

  return <HomeJSX handleGetStarted={handleGetStarted} />;
}

export default Home;