import React from 'react';
import './About.css';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const AboutJSX = () => (
    <div className="About">
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap" rel="stylesheet"></link>
        <div className="header-container">
            <Navbar />
            <header className="about-custom-header">
                <div className="custom-header-content">
                <h1 className="custom-header-title">
                    About Us
                </h1>
                </div>
            </header>
        </div>
        <div className="about-content-container">
            <p className='text-regular'>Welcome to VP Travel Adventures, your destination for exploring the world and rediscovering yourself after the kids have left home. Our blog is dedicated to adventurous parents and adults of any age who are ready to embark on new journeys, experience different cultures, and create unforgettable memories in this exciting new chapter of life.</p>
            <h2 className='text-italics'>Our Story</h2>
            <p className='text-regular'>We are Vince and Polly, passionate travelers who found a renewed sense of exploration and excitement. We realized we aren’t getting any younger and the time to travel is now. Inspired by our own experiences, we created this blog to share the joy of travel and the beauty of life beyond the nest.</p>
            <h2 className='text-italics'>Our Mission</h2>
            <p className='text-regular'>Our mission is to inspire and guide fellow empty nesters as they navigate this transformative phase of life. We believe that travel is a powerful way to rediscover joy, embrace new experiences, and connect with the world in meaningful ways.</p>
            <h2 className='text-italics'>Our Philosophy</h2>
            <p className='text-regular'>We believe that travel is not just about visiting new places, but about discovering new perspectives and finding joy in every journey. We are committed to providing authentic content that inspires and empowers our readers to embrace the adventures that await them.</p>
            <h2 className='text-italics'>Looking Ahead</h2>
            <p className='text-regular'>As we continue to explore the world, we have exciting plans and projects on the horizon. Join us on this journey, and let’s make every moment count. </p>
        </div>   
        <div className="thanks">
            <p className='text-regular'>Thank you for your interest in our traveling adventures. We are excited to share our stories with you and look forward to many more journeys together. Let’s explore, discover, and embrace the joy of life beyond the nest!</p>
        </div>
        <div className="us">
            <img src="/Vince-Polly.png" alt="Vince and Polly" className="us-image" />
            <p className='text-regular-italics-name'>Vince & Polly</p>
            <div className="us-quote">
                <p className='text-regular-italics'>" It doesn’t matter where we go, as long as we’re together."</p>
            </div>
        </div>
        <Footer />
    </div>
);

export default AboutJSX;