import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
// import BlogPostPage from './pages/BlogPostPage/BlogPostPage';
import About from './pages/About/About';

function App() {
  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/post/:id" element={<BlogPostPage />} /> */}
            <Route path="/about" element={<About />} /> 
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;