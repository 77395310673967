import React from 'react';

class FooterJSX extends React.Component {
    render() {
        const currentYear = new Date().getFullYear();

        return (
            <div className="footer-container">
                <div className='footer-links'>
                    <a href="/">Home</a> 
                    <a href="/about">About</a> 
                    <a href="/contact">Contact</a>
                </div>
                <div className='footer-title'>
                    <p>&copy; {currentYear} VP Travel Adventures</p>
                </div>
            </div>
        );
    }
}

export default FooterJSX;