import React from 'react';
import { Button } from 'primereact/button';
import './Home.css';
import './Main.css'
import './Quote.css';
import './AboutMini.css';
import './cta.css';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const HomeJSX = ({ handleGetStarted }) => (
  <div className="home">
    <div className="header-container">
      <Navbar />
      <header className="custom-header">
        <div className="custom-header-content">
          <h1 className="custom-header-title">
            embrace your next
            <br />
            <span className="custom-header-title-emphasis">adventure</span>
          </h1>
          <p className="custom-header-description">
            It's never too late to discover new passions and live life to the fullest!
            Join us as we explore the world, one adventure at a time.
          </p>
          {/* <Button 
            label="Get Started" 
            className="p-button-success" 
            onClick={handleGetStarted} 
          /> */}
        </div>
      </header>
    </div>
    <div className="content">
      <h1 className="main-title">VP Travel Adventures</h1>
      <p className="intro-text">
        Welcome to our blog, your guide to exploring the world and rediscovering yourself after the kids have flown the 
        coop. Our blog is dedicated to not only adventurous parents but to all who are ready to embark on new journeys, 
        experience different cultures, and create unforgettable memories in this exciting new chapter of life.
      </p>
      <p className="section-title">Here, you'll find:</p>
      <ul className="feature-list">
        <li>Travel Tips and Guides: Advice on planning your trips, finding the best destinations, and making the most of your travels.</li>
        <li>Destination Highlights: In-depth features on must-visit places, hidden gems, and off-the-beaten-path adventures.</li>
        <li>Lifestyle Insights: Stories and tips on adjusting to empty nest life, from rekindling hobbies to embracing newfound freedom.</li>
      </ul>
      <div className="featured-content">
        <div className="feature-item">
          <img src="ice-water.jpg" alt="Alaska Cruise" className="feature-image" />
          <h3>10 Day Alaska Cruise</h3>
          <p>The upcoming posts will cover our 10 day Alaska Cruise, including the excursions, pre & post hotel stay and of course our time on the NCL Sun.</p>
        </div>
        <div className="feature-item">
          <img src="/alaska-packing.jpg" alt="Resources" className="feature-image" />
          <h3>Resources</h3>
          <p>We will be sharing printable forms as well as product reviews.</p>
        </div>
        <div className="feature-item">
          <img src="/castle.jpg" alt="Upcoming Trips" className="feature-image" />
          <h3>Upcoming Trips</h3>
          <p>We have a few spots available to join us on an October 2025 trip to the Republic of Ireland, Northern Ireland and Scotland. Message us if interested.</p>
        </div>
      </div>
    </div>
    <div className="quote-banner">
      <p className="quote-text">
        "We travel not to escape life, but for life not to escape us."
      </p>
      <p className="quote-author">– Anonymous</p>
    </div>
    <div className="about-us-section">

      <div className="about-us-content">
        <div className="about-us-centered-container">
          <h2 className="about-us-subtitle">ABOUT US</h2>
          <h3 className="about-us-title">
            VP Travel Adventures isn't just another travel blog; it's a gateway to new experiences for those ready to rediscover themselves.
          </h3>
          <p className="about-us-description">
            Whether you're planning your first post-nest adventure or looking for ways to enhance your travels, our blog is here to inspire and support you every step of the way. Join us in discovering the endless possibilities that await beyond the nest!
          </p>
          <div className="about-us-button">
            <Button label="Find Out More" className="p-button-outlined find-out-more-btn" />
          </div>
        </div>
      </div>
      <div className="about-us-image">
        <img src="world.jpg" alt="Hands holding a globe" />
      </div>

    </div>
    <div className="beach-cta-container">
      <img
        src="contact.jpg"
        alt="Horses on a beach"
        className="beach-cta-image"
      />
      <div className="beach-cta-overlay">
        <p className="beach-cta-text">
          Whether you're curious about upcoming trips or would like to join
          us on one of our adventures, we're here to answer any questions.
        </p>
        <Button label="Let's Talk Now" className="p-button-success" />
      </div>
    </div>
    <div className="footer">
      <Footer />
    </div>
  </div>
);

export default HomeJSX;