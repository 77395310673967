import React from 'react';
import { Menubar } from 'primereact/menubar';

const NavbarJSX = ({ items }) => {
    const start = (
        <a href="/" className="home-link">
          <img src="/logo.png" alt="VP Travel Adventures Logo" className="logo-image" />
        </a>
      );

    return (
        <div className="navbar-container">
            <div className="navbar-content">
                <Menubar model={items} start={start} className="custom-menubar" />
            </div>
        </div>
    );
};

export default NavbarJSX;